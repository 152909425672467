<template>
    <div class="bg-img-custom p-4">
        <!-- <PWAPrompt :promptOnVisit="1" :timesToShow="3" :permanentlyHideOnDismiss="false" /> -->
        <div class="justify-content-center">
            <div class="row">
                <div class="col-lg-12 p-5 text-center">
                    <div class="mx-auto mb-5">
                        <a href="/">
                            <img src="@assets/images/logo.png" alt height="48" />
                        </a>
                        <!-- <h4 class="text-white mt-0 mb-2">Enterprise</h4> -->
                        <h6 class="text-white">Version: {{ currentVersion }}</h6>
                    </div>
                </div>
                <div class="col-lg-12 mb-4">
                    <div class="text-center mb-4">
                        <a class="mr-2" href="https://play.google.com/store/apps/details?id=com.tawcs.onsite.app"
                            target="_blank">
                            <img src="@assets/images/app/google-play-badge.png" style="width: 45%;" /></a>
                        <a href="https://apps.apple.com/au/app/tawcs-enterprise-on-site/id1637364665?platform=iphone"
                            target="_blank">
                            <img src="@assets/images/app/apple-store-badge-.png" style="width: 45%;" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bar p-2" v-if="currentLocation">
            <h4 class="text-white">{{ currentLocation.name }}</h4>
            <b-button id="goFS" :to='`/openapp/${currentLocation.keyId}/default-procedure`' block variant="primary">View
                Procedures</b-button>
        </div>
    </div>
</template>

<script>

import {
    externalData
} from '@/src/state/api/pwaexternal';

import {
    version
} from "@/package.json";

export default {
    page: {
        title: 'Lets get started',
    },
    data() {
        return {
            deferredPrompt: null,
            currentVersion: version,
            currentLocation: null
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    components: {
    },
    async created() {
        this.captureEvent()
        await this.loadLocation()
    },
    methods: {
        async loadLocation() {
            this.currentLocation = await externalData.searchLocationByCode({
                qrCode: this.id,
                locationId: ''
            })
        },
        SignIn() {
            this.$router.push("/signin");
        },
        beginScanning() {
            this.showNotification();
        },
        showNotification() {
            Notification.requestPermission(function (result) {
                console.log(result);
                if (result !== 'granted') {
                    this.$vs.notify({
                        title: "Error",
                        text: "Permission for notifications has been denied, please go to settings and allow notifications.",
                        color: "danger",
                        position: "top-center",
                        time: 5
                    });
                    return;
                    // navigator.serviceWorker.ready.then(function (registration) {
                    //     registration.showNotification('Vibration Sample', {
                    //         body: 'Buzz! Buzz!',
                    //         icon: '../images/touch/chrome-touch-icon-192x192.png',
                    //         vibrate: [200, 100, 200, 100, 200, 100, 200],
                    //         tag: 'vibration-sample123'
                    //     });
                    // });                    
                }
            });
            this.$router.push("/ScanLocationCode");
        },
        captureEvent() {
            window.addEventListener('beforeinstallprompt', (e) => {
                console.log("installation Data", e);
                e.preventDefault()
                this.deferredPrompt = e
                this.clickCallback();
            })
        },
        clickCallback() {
            this.deferredPrompt.prompt()
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') { }
                this.deferredPrompt = null
            })
        },
    }
}
</script>
